import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/common/components/localized-client-link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/common/components/max-width-wrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/modules/common/components/prefooter-socials/prefooterstyle.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/home/components/clients/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/home/components/featured-carousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/home/components/features/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/home/components/hero/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/modules/home/components/vision-promises/index.tsx");
