"use client";
import React from "react";
import CtaButton from "@/modules/common/components/cta-button";

import styles from "./visionpromisesstyles.module.scss";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";

const VisionPromises = () => {
  const cards = [
    {
      header: "For Product Vendors",
      description:
        "Offer your products at scale to reputable partners in the region, ensuring successful market entry and new revenue streams.",
    },
    {
      header: "For Medical and Wellness Providers",
      description:
        "Access comprehensive longevity solutions tailored for your medical facility, gym, or wellness center.",
    },
    // {
    //   header: "Healthspan As a Service",
    //   description:
    //     "Access comprehensive lifespan and healthspan solutions tailored for your medical facility, gym, or wellness center.",
    // },
  ];
  return (
    <>
      <div
        className={`${styles.vision_promises_container} w-full px-5 sm:px-14 py-14 pt-24`}
      >
        <WrapperContainer>
          <div className="block lg:grid grid-cols-8 gap-12">
            <div className="col-span-4 pb-14">
              {/* <ul className="list-disc pl-8 sm:pl-4 text-3xl mb-6 mt-[-10px]">
                <li>Our Partners</li>
              </ul> */}
              <p
                className={`${styles.promise_text}  mb-12 text-5xl tracking-lg`}
              >
                Innovate and grow faster with Geneseq partners across longevity
                and wellness.
              </p>
              <div className="flex items-center justify-start">
                <CtaButton href={"/about"}>About Geneseq</CtaButton>
              </div>
            </div>
            <div className="col-span-4">
              {cards.map((card, index) => (
                <div key={index} className="bg-white p-6 rounded-lg  mb-6">
                  <button className={styles.card_button}></button>
                  <h3
                    className={`${styles.card_header} text-sans text-3xl text-customGray leading-normal my-4`}
                  >
                    {card.header}
                  </h3>
                  <p className={`${styles.card_description} my-4`}>
                    {card.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
};

export default VisionPromises;
