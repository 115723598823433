"use client";

import React from "react";
import CtaButton from "@/modules/common/components/cta-button";

import styles from "./herostyle.module.scss";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";
import useCustomer from "@/lib/hooks/useCustomer";
import useCart from "@/lib/hooks/useCart";
import HeaderCart from "@/modules/layout/templates/nav/components/header-cart";

const Hero = () => {
  const { customer } = useCustomer();

  const { cart, totalQuantity, isVisible, updateLineItem } = useCart();
  return (
    <>
      <div
        className={`${styles.hero_container} px-5 md:px-14 flex items-center justify-center h-screen bg-cover bg-center`}
      >
        <WrapperContainer>
          <div className="text-center">
            <h1
              className={`${styles.header_text} text-4xl font-bold mb-6 sm:mb-12 mx-auto`}
            >
              Welcome To The Ultimate Longevity Marketplace
            </h1>
            <p className={`${styles.text} text-2xl mx-auto mb-8 sm:mb-14`}>
              Explore the latest products in longevity and precision medicine.
            </p>
            <div className="flex items-center justify-center">
              <CtaButton href={`/shop`}>Shop Now</CtaButton>
            </div>
          </div>
        </WrapperContainer>
      </div>
      {customer && (
        <HeaderCart
          quantity={totalQuantity}
          cart={cart}
          isVisible={isVisible}
          updateLineItem={updateLineItem}
        />
      )}
    </>
  );
};

export default Hero;
