"use client";
import React from "react";
import CtaButton from "@/modules/common/components/cta-button";

import styles from "./featurestyles.module.scss";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";

const cardContents = [
  {
    number: 1,
    title: "Pioneering Longevity Science",
    description:
      "Geneseq offers groundbreaking research and products that push the boundaries of what's possible in extending healthy longevity.",
  },
  {
    number: 2,
    title: "Curated and Vetted Products",
    description:
      "All products are carefully curated and vetted by Geneseq’s executive team, ensuring they meet standards of quality and efficacy.",
  },
  {
    number: 3,
    title: "Access New Markets and Customers",
    description:
      "Geneseq connects your products with leading medical and wellness institutions, opening doors to new markets and customer bases.",
  },
  {
    number: 4,
    title: "Commercial Advantage",
    description:
      "Geneseq provides exclusive and wholesale prices as well as tools and resources you need to stay ahead of the competition.",
  },
];

const Features = () => {
  return (
    <div className={` ${styles.features_container} w-full px-5 md:px-14 pt-20`}>
      <WrapperContainer>
        <div className="flex justify-center">
          <h2
            className={`${styles.header_text} text-2xl text-center text-black max-w-[1100px]`}
          >
            Geneseq Marketplace is a B2B online broker connecting vendors and
            licensees via innovative longevity and precision medicine products.
          </h2>
        </div>
      </WrapperContainer>
    </div>
  );
};

export default Features;
